const siteUrl = 'https://mrsurething.uplancer.agency';
const siteAddress = new URL(siteUrl);
const canonical = siteAddress.href.slice(0, -1);
const title = 'Mr. Sure Thing | Smart Sports Betting, Made Easy';
const description = 'Smart Sports Betting, Made Easy';
const fbAppId = null;

/* https://github.com/garmeeh/next-seo */
module.exports = {
    title,
    canonical,
    description,
    openGraph: {
        type: 'website',
        url: siteUrl,
        title,
        description,
        site_name: title,
        images: [
            {
                url: '/img/mst-logo.svg',
                alt: title,
            },
        ],
    },
    twitter: {
        handle: '',
        site: '',
    },
    facebook: fbAppId
        ? {
              appId: fbAppId,
          }
        : undefined,
    additionalLinkTags: [
        {
            href: `/favicon.ico`,
            rel: 'icon',
        },
    ],
};
