export class APIError<T> extends Error {
    response?: Response;
    data?: T;

    constructor(message?: string) {
        super(message);
    }
}

export function normalizeData<T, V>(
    rows: T | T[],
    mapper: (row: T) => V
): V | V[] {
    return Array.isArray(rows) ? rows.map(mapper) : mapper(rows);
}

export function getHost(endpoint?: string): string | undefined {
    const matches = endpoint?.match(/^http[s]?:\/\/([^\/]+)/i);
    return matches && matches.length > 1 ? matches[1] : undefined;
}

type Promisable<T> = T | Promise<T>;
type Iterator<T, U> = (item: T) => Promisable<U>;
export async function batchPromises<T, U>(
    batchSize: number,
    collection: Promisable<T[]>,
    callback: Iterator<T, U>,
    batchCallback?: (batchResults: U[], batchIdx: number) => Promisable<void>
): Promise<U[]> {
    return Promise.resolve(collection).then((arr) =>
        arr
            .map((_, i) => (i % batchSize ? [] : arr.slice(i, i + batchSize)))
            .filter((group) => group.length > 0)
            .map(
                (group, gIdx) => (res: any) =>
                    Promise.all(group.map(callback)).then(async (r) => {
                        if (batchCallback) {
                            await batchCallback(r, gIdx);
                        }

                        return res.concat(r);
                    })
            )
            .reduce((chain, work) => chain.then(work), Promise.resolve([]))
    );
}

export async function fetchJson<T>(
    input: RequestInfo,
    init?: RequestInit
): Promise<T> {
    try {
        const response = await window.fetch(input, init);

        // if the server replies, there's always some data in json
        // if there's a network error, it will throw at the previous line
        const data = await response.json();

        if (response.ok) {
            return data as T;
        }

        const error = new APIError<T>(response.statusText);
        error.response = response;
        error.data = data;
        throw error;
    } catch (err: any) {
        if (!err.data) {
            err.data = { message: err.message };
        }
        throw err;
    }
}
