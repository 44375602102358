import React from 'react';
import { ThemeProvider } from '@xstyled/styled-components';
import seo from 'config/seo';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { createGlobalStyle } from 'styled-components';
import 'styles/app.scss';
import 'styles/bootstrap-custom.scss';
import 'styles/responsive.scss';
import 'styles/table_style.scss';
import { SWRConfig } from 'swr';
import theme from 'theme';
import { fetchJson } from 'utils/api';

const GlobalStyle = createGlobalStyle`
    html {
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        overflow-x: hidden;
        font-family: 'Open Sans', sans-serif;
    }

    body {
        font-size: 16px;
        margin: 0;
        color: rgba(49, 50, 58, 0.7);
        overflow-x: hidden;
        font-family: 'Open Sans', sans-serif;

        word-break: normal;
        hyphens: none;
    }

    main {
        min-height: 50vh;
    }

    h1 {
        font-size: 46px;
        line-height: 1.0833333333333333;
    }

    h2 {
        font-size: 35px;
        line-height: 1.4444444444444444;
    }

    h3 {
        font-size: 26px;
        line-height: 1.0833333333333333;
    }

    h4 {
        font-size: 20px;
        line-height: 1.2380952380952381;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #31323a;
        font-weight: 700;
        font-family: 'Ubuntu', sans-serif;
    }

    p {
        font-size: 16px;
        color: #31323a;
        line-height: 1.625;
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
    }

    a {
        color: #31323a;
        text-decoration: none;
    }

    a,
    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        outline: none;
    }

    a i {
        padding: 0 2px;
    }

    img {
        max-width: 100%;
    }

    /*input and button type focus outline disable*/
    input[type='text']:focus,
    input[type='email']:focus,
    input[type='url']:focus,
    input[type='password']:focus,
    input[type='search']:focus,
    input[type='tel']:focus,
    input[type='number']:focus,
    textarea:focus,
    input[type='button']:focus,
    input[type='reset']:focus,
    input[type='submit']:focus,
    select:focus {
        outline: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: 1px solid #ddd;
    }
`;

export default function App({ Component, pageProps }: AppProps) {
    return (
        <ThemeProvider theme={theme}>
            <SWRConfig
                value={{
                    fetcher: fetchJson,
                    onError: (err) => {
                        console.error(err);
                    },
                }}
            >
                <DefaultSeo {...seo} />

                <GlobalStyle />
                <Component {...pageProps} />
            </SWRConfig>
        </ThemeProvider>
    );
}
